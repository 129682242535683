import { NavLink } from "react-router-dom";
import "./DropDown.css";

export const DropDown = () => {
  const activeClass =
    "text-base block py-2 pl-3 pr-4 text-white bg-gray-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500";
  const inactiveClass =
    "text-base block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";

  return (
    <ul className=" gap-1 flex flex-col p-3 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0  dark:border-gray-700">
      <li>
        <a
          href="mailto:a.schaefer@primagency.de?subject=Serviceanfrage"
          className="custom-link"
          style={{
            display: "block",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            paddingRight: "16px",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: "white",
            borderRadius: "0.25rem",
            borderColor: "#374151",
          }}
        >
          Service erhalten
        </a>
      </li>
      <li>
        <NavLink
          to="/karriere-aufbauen"
          className={({ isActive }) => (isActive ? activeClass : inactiveClass)}
        >
          Karriere aufbauen
        </NavLink>
      </li>
    </ul>
  );
};
