import pic from "../../assets/images/karriere_pic_cut.png";
import { JobCard } from "./components/JobCard";
import "./Karriere.css";
import { TsWidget } from "../../components/Other/TsWidget";
import { useState } from "react";
import { Banner, Button } from "flowbite-react";
import { HiArrowRight, HiX } from "react-icons/hi";
import { BiSolidError } from "react-icons/bi";

export const Karriere = () => {
  const [turnstileStatus, setTurnstileStatus] = useState();
  const [turnstileKey, setTurnstileKey] = useState(0);

  const handleTurnstileStatusChange = (status) => {
    setTurnstileStatus(status);
  };

  const triggerRevalidation = () => {
    // Ändert den Schlüssel, was das Turnstile-Widget neu rendert
    setTurnstileKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <div className="pt-20">
        <div className="w-3/4 flex flex-col justify-center px-10 ls:w-full  ls:text-center mobile:px-0">
          <h1 className="prim_h1 text-center">Offene Stellen</h1>
          <div className="prim_h1_sub flex flex-col gap-5 text-center">
            <span>Vor allem wichtig: Dein Performance-Mindset.</span>
            <span> Bitte wähle den Bereich, der dich interessiert:</span>
          </div>
          <section className="max-w-7xl mx-auto py-7">
            <div className="flex justify-center flex-wrap other:justify-evenly">
              <JobCard
                turnstileStatus={turnstileStatus}
                title="Entwicklung"
                description="Komm zu PRIM als Entwickler/-in und gestalte mit uns zusammen Lösungen für Morgen, mit den Technologien von heute."
                path="/PRIM_Jobs_Webentwicklung_2024_01_17.pdf"
                indeedLink="https://de.indeed.com/job/entwickler-fokus-webentwicklung-randthemen-software-architektur-c018f1291d3af022"
              >
                <svg width="24" height="29" viewBox="0 0 24 29" fill="none">
                  <path
                    d="M23.6667 4.7C23.6667 2.3844 21.6349 0.5 19.1383 0.5C16.6416 0.5 14.6099 2.3844 14.6099 4.7C14.6099 6.2596 15.5412 7.6078 16.9058 8.3316C16.7503 10.2104 15.0703 11.7 13.0053 11.7H10.3034C8.84371 11.7 7.49122 12.1242 6.3712 12.8396V8.6424C8.12369 8.0628 9.39013 6.5228 9.39013 4.7C9.39013 2.3844 7.35839 0.5 4.86173 0.5C2.36508 0.5 0.333334 2.3844 0.333334 4.7C0.333334 6.5228 1.59978 8.0628 3.35227 8.6424V20.359C1.59978 20.9372 0.333334 22.4772 0.333334 24.3C0.333334 26.6156 2.36508 28.5 4.86173 28.5C7.35839 28.5 9.39013 26.6156 9.39013 24.3C9.39013 22.4772 8.12369 20.9372 6.3712 20.3576V18.1456C6.3712 16.1352 8.13426 14.5 10.3034 14.5H13.0053C16.551 14.5 19.4522 12.0164 19.8719 8.8314C22.0198 8.5038 23.6667 6.7832 23.6667 4.7Z"
                    fill="#ffffff"
                  />
                </svg>
              </JobCard>
              <JobCard
                turnstileStatus={turnstileStatus}
                title="Anforderungsdesign"
                description="Komm zu PRIM als Konzepter/-in und gestalte mit uns zusammen Lösungen, mit denen Menschen gerne & zielführend interagieren"
                path="/PRIM_Jobs_Anforderungsaufnahme_2024_01_17.pdf"
                indeedLink="https://de.indeed.com/job/anforderungsdesigner-konzeption-ui-ux-design-prozessoptimierung-9c3505ff82958cbf"
              >
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
                  <path
                    d="M28.0898 9.09064L19.9096 0.910545C19.3622 0.363152 18.4774 0.363152 17.93 0.910545L15.9084 2.93212C15.6452 3.19532 15.4982 3.54951 15.4982 3.92191C15.4982 4.2943 15.6452 4.6499 15.9084 4.9117L24.0886 13.0918C24.3616 13.3648 24.72 13.502 25.0784 13.502C25.4368 13.502 25.7952 13.3648 26.0682 13.0918L28.0898 11.0702C28.353 10.807 28.5 10.4528 28.5 10.0804C28.5 9.70803 28.3516 9.35244 28.0898 9.09064Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M22.2028 13.1884L15.8118 6.79887C15.4464 6.43348 14.9116 6.29768 14.416 6.44888L6.77627 8.76025C6.35068 8.88904 6.01048 9.21524 5.86348 9.63384L0.579896 24.5884C0.400697 25.0966 0.528097 25.6636 0.910295 26.0444L0.942495 26.0766L10.1909 16.8283C10.0089 16.3902 9.91366 15.9156 9.91366 15.427C9.91366 14.4484 10.2945 13.53 10.9861 12.8384C11.6777 12.1468 12.5947 11.766 13.5733 11.7646C14.5504 11.7646 15.4688 12.1454 16.1618 12.8384C16.852 13.5286 17.2328 14.447 17.2328 15.427C17.2328 16.4042 16.852 17.3225 16.1604 18.0141C15.4464 18.7281 14.5098 19.0851 13.5733 19.0851C13.0973 19.0851 12.6213 18.9899 12.1733 18.8065L2.92069 28.0576L2.95289 28.0898C3.22169 28.3558 3.58009 28.5 3.94408 28.5C4.10088 28.5 4.25768 28.4734 4.41028 28.4202L19.365 23.1381C19.785 22.9897 20.1098 22.6495 20.2386 22.2239L22.5528 14.5842C22.7026 14.0886 22.5682 13.5524 22.2028 13.1884Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M14.1808 16.0346C14.3432 15.8722 14.4328 15.6552 14.4328 15.427C14.4328 15.1974 14.3446 14.9818 14.1808 14.818C14.017 14.6542 13.8015 14.5646 13.5719 14.5646C13.3423 14.566 13.1281 14.6542 12.9657 14.818C12.8033 14.9804 12.7137 15.1974 12.7137 15.427C12.7137 15.6552 12.8019 15.8708 12.9657 16.0346C13.3003 16.3678 13.8462 16.3678 14.1808 16.0346Z"
                    fill="#ffffff"
                  />
                </svg>
              </JobCard>
            </div>
            <TsWidget
              onStatusChange={handleTurnstileStatusChange}
              key={turnstileKey}
            />
          </section>
        </div>
        <div className="fixed right-0 top-0 bottom-0 left-auto w-1/4 pt-10 ls:hidden">
          <img src={pic} className="h-full w-full object-cover" />
        </div>
      </div>
      {turnstileStatus === "error" && (
        <Banner className="fixed bottom-0 right-0 left-0 z-50 ">
          <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-8 dark:border-gray-600 dark:bg-gray-700">
            <div className="mx-auto flex items-center gap-6 ls:flex-col">
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 gap-6">
                <BiSolidError className="h-10 w-10 shrink-0" />
                <span className="[&_p]:inline">
                  Es sieht so aus, als hätte unsere automatische Bot-Prüfung
                  gerade nicht funktioniert. Keine Sorge, das kann gelegentlich
                  passieren. Bitte klicken Sie auf den Button unten, um eine
                  kurze Verifizierung durchzuführen und sicherzustellen, dass
                  Sie kein Roboter sind. Wir bringen Sie schnellstmöglich wieder
                  auf Kurs!
                </span>
              </p>
              <Button
                pill
                style={{
                  color: "#abcfd3",
                  background: "rgba(171, 207, 211, 0.16)",
                  border: "1px solid #abcfd3",
                  padding: "6px 0",
                }}
                className="shrink-0"
                onClick={triggerRevalidation}
              >
                Erneute Verifizierung
              </Button>
            </div>
          </div>
        </Banner>
      )}
    </>
  );
};
