import { Turnstile } from "@marsidev/react-turnstile";
import { useEffect, useState } from "react";

export const TsWidget = ({ onStatusChange, key }) => {
  const [status, setStatus] = useState();

  useEffect(() => {
    onStatusChange(status);
  }, [status, onStatusChange]);

  return (
    <Turnstile
      // passtest
      // siteKey="1x00000000000000000000BB"
      // blocktest
      siteKey="2x00000000000000000000BB"
      //   prim-agency.de domain siteKey
      // siteKey="0x4AAAAAAAQ0j3uNLcgSdzt-"
      onError={() => setStatus("error")}
      onExpire={() => setStatus("expired")}
      onSuccess={() => setStatus("solved")}
      key={key}
    />
  );
};
