import { Button, Card } from "flowbite-react";

import { useConfig } from "../../../context/ConfigContext";
import { useEffect } from "react";
import { SiIndeed } from "react-icons/si";
import { ConditionalLink } from "../../../components/Other/ConditionalLink";

export const JobCard = ({
  children,
  title,
  description,
  path,
  indeedLink,
  turnstileStatus,
}) => {
  const config = useConfig();

  useEffect(() => {
    if (!config || !config.apiBaseUrl) {
      return undefined;
    }
  }, [config]);

  return (
    <>
      {config && config.apiBaseUrl && (
        <Card className="w-96 m-3 mobile:w-98%">
          {children}
          <h2 className="font-normal my-0 text-2xl">{title}</h2>
          <p className="mb-4">{description}</p>
          <div className="flex gap-4">
            <ConditionalLink
              href={`${config.apiBaseUrl}/jobs${path}`}
              turnstileStatus={turnstileStatus}
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={indeedLink}
              className="flex-grow"
            >
              <Button
                pill
                style={{
                  color: "#abcfd3",
                  background: "rgba(171, 207, 211, 0.16)",
                  border: "1px solid #abcfd3",
                  padding: "6px 0",
                  width: "100%",
                }}
              >
                <SiIndeed className="mr-6 h-5 w-5" />
                Indeed
              </Button>
            </a>
          </div>
        </Card>
      )}
    </>
  );
};
