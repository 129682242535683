import { Banner, Button } from "flowbite-react";
import React, { useState } from "react";
import { FaCookieBite } from "react-icons/fa6";
import { HiX } from "react-icons/hi";

export const Matomo = () => {
  const [showBanner, setShowBanner] = useState(false);

  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.src = "https://prim-agency.de/matomo/matomo/js/container_eA5EdmlK.js";
  s.parentNode.insertBefore(g, s);

  const handleBannerToggle = (mode) => {
    setShowBanner(mode);
  };

  if (showBanner) {
    return (
      <Banner className="fixed bottom-0 right-0 left-0 z-50 max-h-40% overflow-auto">
        <div className="flex w-full justify-between border-t p-8 border-layout bg-layout">
          <div className="mx-auto flex items-center gap-10 flex-row ls:flex-col">
            <FaCookieBite className=" h-20 w-20 shrink-0 tablet:h-14 tablet:w-14" />
            <div className="mx-auto flex items-center gap-6 flex-col">
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 gap-6">
                <span className="[&_p]:inline">
                  <h4>Willkommen bei PRIM</h4>
                  <b>Datenschutz und Sicherheit sind uns wichtig:</b>
                  <br></br>
                  <br></br>
                  <b>Turnstile für Sicherheit (technisch notwendig)</b>:{" "}
                  <br></br>Um Ihre Daten und unsere Website vor Bots zu
                  schützen, verwenden wir Turnstile. Diese Sicherheitsmaßnahme
                  ist notwendig für den Betrieb unserer Website und kann nicht
                  deaktiviert werden. Sie läuft im Hintergrund und
                  beeinträchtigt Ihr Erlebnis nicht. <br></br>
                  <br></br>
                  <b>Matomo für bessere Nutzererfahrung:</b>
                  <br></br>Um unser Angebot zu verbessern, verwenden wir Matomo.
                  Dies hilft uns, das Nutzerverhalten besser zu verstehen und
                  unsere Website entsprechend anzupassen. Wir ergreifen hierbei
                  alle notwenidgen Maßnahmen, um das Sammeln personenbezogener
                  Daten zu verhindern.
                </span>
              </p>
            </div>
            <div className="h-full flex items-start absolute top-6 right-6">
              <button
                className="p-2 hover:bg-slate-700 rounded-md"
                onClick={() => handleBannerToggle(false)}
              >
                <HiX className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </Banner>
    );
  }

  if (!showBanner) {
    return (
      <Button
        pill
        style={{
          color: "#abcfd3",
          background: "rgba(171, 207, 211, 0.16)",
          border: "1px solid #abcfd3",
        }}
        className="fixed bottom-4 left-4 w-14 h-14 z-50"
        onClick={() => handleBannerToggle(true)}
      >
        <FaCookieBite className="h-10 w-10 shrink-0" />
      </Button>
    );
  }

  return null;
};
