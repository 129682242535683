import logo_big from "../../assets/images/welcome_logo_big.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Navbar } from "flowbite-react";
import { BurgerButton } from "./Header/BurgerButton";
import { DropDown } from "./Header/DropDown";

export const Header = () => {
  const [hidden, setHidden] = useState(true);

  return (
    <Navbar
      fluid
      className="fixed top-0 left-0 right-0 bottom-auto z-50"
      style={{ background: "#212121" }}
    >
      <Navbar.Brand as={Link} to="/">
        <img
          src={logo_big}
          className="mr-3 h-6 sm:h-9"
          alt="Flowbite React Logo"
        />
      </Navbar.Brand>
      <div className="hidden md:order-2 md:flex flex-grow pl-6">
        <a
          href="mailto:a.schaefer@primagency.de?subject=Serviceanfrage"
          className="prim_button_onlytext primary floatleft w-button mr-4"
        >
          Service erhalten
        </a>
        <Link
          to="/karriere-aufbauen"
          className="prim_button_onlytext primary floatleft w-button"
        >
          Karriere aufbauen
        </Link>
      </div>
      <div className="flex md:order-2">
        <BurgerButton hidden={hidden} setHidden={setHidden} />
      </div>

      <div
        className={`items-center justify-between ${
          hidden ? "hidden" : ""
        } w-full md:none md:w-auto md:order-1`}
        id="navbar-search"
      >
        <DropDown />
      </div>
    </Navbar>
  );
};
