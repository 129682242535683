import { FiDownload } from "react-icons/fi";
import { BiSolidError } from "react-icons/bi";
import { Button } from "flowbite-react";
export const ConditionalLink = ({ turnstileStatus, href }) => {
  return turnstileStatus === "error" ? (
    <span className="flex-grow">
      <Button
        pill
        style={{
          color: "#5a5a5a",
          background: "rgba(171, 207, 211, 0.08)",
          border: "1px solid #5a5a5a",
          padding: "6px 0",
          width: "100%",
          cursor: "not-allowed",
        }}
      >
        <BiSolidError className="mr-4 h-5 w-5" />
        Fehlgeschlagen
      </Button>
    </span>
  ) : turnstileStatus === "solved" ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="flex-grow"
      href={href}
    >
      <Button
        pill
        style={{
          color: "#abcfd3",
          background: "rgba(171, 207, 211, 0.16)",
          border: "1px solid #abcfd3",
          padding: "6px 0",
          width: "100%",
        }}
      >
        <FiDownload className="mr-4 h-5 w-5" />
        Details
      </Button>
    </a>
  ) : (
    <span className="flex-grow">
      <Button
        pill
        isProcessing
        style={{
          color: "#abcfd3",
          background: "rgba(171, 207, 211, 0.16)",
          border: "1px solid #abcfd3",
          padding: "6px 0",
          width: "100%",
        }}
      >
        Loading
      </Button>
    </span>
  );
};
